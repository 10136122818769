<template>
  <huboo-full-screen-dialog :value="value" @input="$emit('input', $event)" :title="heading | title">
    <huboo-list
      max-width="600px"
      class="pa-4 mx-auto text-center"
      clickable
      @clicked="handleItemClicked"
      :items="items"
    >
    </huboo-list>
    <huboo-modal
      v-for="(item, index) in items"
      :key="'editModal' + index"
      maxWidth="600px"
      v-model="editModals[item.name]"
    >
      <v-card>
        <huboo-form
          id="additionalPricingForm"
          :title="$t('common.edit') | title"
          @submit="onSubmit"
          :fields="[item]"
          v-if="editModals[item.name]"
          :loading="loadingForm"
        >
        </huboo-form>
      </v-card>
    </huboo-modal>
  </huboo-full-screen-dialog>
</template>

<script>
import {
  FIELDS,
  priceField,
  priceListCodeField,
  descriptionField,
  originRegionField,
} from '@/views/pages/billing/additionalPricingModalHelpers'

export default {
  name: 'AdditionalPricingEditModal',
  props: {
    value: Boolean,
    selected: { type: Object, required: true },
  },
  data: () => ({
    editModals: FIELDS.reduce((a, v) => {
      a[v] = false
      return a
    }, {}),
  }),
  computed: {
    heading() {
      return this.$t('billing.additionalPricing')
    },
    fields() {
      return {
        originRegion: originRegionField(),
        priceListCode: priceListCodeField(),
        description: descriptionField(),
        price: priceField(),
      }
    },
    items() {
      return FIELDS.map(f => ({ ...this.fields[f], name: f })).map(f => ({
        ...f,
        errorMessages: this.$store.getters['core/validationError'](f.name),
        text: this.selected?.[f.name],
        title: f.label,
        value: Object.prototype.hasOwnProperty.call(f, 'value') || this.selected?.[f.name],
        disabled: f.name === 'originRegion',
      }))
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    loadingForm() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'PATCH',
        url: 'billing-price-list/' + this.selected?.id,
      })
    },
  },
  methods: {
    async onSubmit({ fields, values }) {
      await this.$store.dispatch('billing/patchBillingPriceLists', {
        id: this.selected?.id,
        data: { ...this.selected, ...values },
      })
      if (!this.hasErrors) {
        this.$emit('selected:updated')
        fields.forEach(f => {
          this.editModals[f.name] = false
        })
      }
    },
    handleItemClicked(e) {
      this.editModals[e.name] = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
