import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import {
  isFloat,
  isGreaterThanOrEqualToN,
  isLessThanOrEqualToN,
  isRequired,
  isRoundedToNDecimalPlaces,
} from '@/utilities/validations'
import store from '@/boot/store'

export const defaultPriceLists = () =>
  store.getters['billing/getBillingDefaultPriceLists']?.data || []

export const FIELDS = ['originRegion', 'priceListCode', 'description', 'price']

export const descriptionField = () => {
  return {
    name: 'description',
    label: title(i18n.t('common.description')),
    type: 'text',
    rules: [isRequired],
  }
}

export const getPriceCodes = () =>
  [...defaultPriceLists()]
    ?.filter(d => d.originRegion === store.getters['billing/getSelectedRegion'])
    ?.filter(d => d.currency === store.getters['billing/getSelectedBox']?.currency)

export const priceField = () => {
  return {
    name: 'price',
    label: title(i18n.t('common.price')),
    min: 0,
    max: 1000000,
    placeholder: '0.00',
    step: 0.01,
    rules: [
      v => isFloat(v),
      v => isGreaterThanOrEqualToN(v, 0),
      v => isLessThanOrEqualToN(v, 1000000),
      v => isRoundedToNDecimalPlaces(v, 2),
    ],
  }
}

export const priceListCodeField = () => {
  return {
    name: 'priceListCode',
    label: title(i18n.t('billing.priceListCode')),
    component: 'v-select',
    items: getPriceCodes().map(p => ({ text: p.priceListCode, value: p.priceListCode })),
    rules: [
      v =>
        [...getPriceCodes().map(item => item.priceListCode), null].includes(v) ||
        i18n.t('billing.priceListCodeNotAllowed'),
    ],
  }
}

export const originRegionField = () => {
  return {
    name: 'originRegion',
    component: 'v-select',
    label: title(i18n.t('billing.originRegion')),
    items: store.getters['billing/getRegions']?.data?.filter(r => r.origin) || [],
    itemText: 'displayName',
    itemValue: 'code',
    type: 'text',
    rules: [isRequired],
  }
}
