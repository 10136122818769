<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.additionalPricing') | title"></huboo-page-heading>

    <huboo-table
      id="additionalPricingTable"
      @row-clicked="onRowSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      hide-search
      hide-date-picker
      :filters="filters"
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      localPagination
      :server-items-length="serverItemsLength"
      :loading="loading"
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      :remove="isAdmin"
    >
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />

        <v-btn
          :disabled="loading"
          color="primary"
          @click="onCreateSelected"
          id="additionalPricingCreateButton"
        >
          {{ $t('billing.createAdditionalPricing') }}
        </v-btn>
      </template>
    </huboo-table>

    <component
      :is="modal.component"
      v-model="modal.show"
      :selected="selected"
      @selected:updated="onSelectedUpdated"
    ></component>
  </huboo-page>
</template>

<script>
import AdditionalPricingCreateModal from '@/views/pages/billing/AdditionalPricingCreateModal'
import AdditionalPricingEditModal from '@/views/pages/billing/AdditionalPricingEditModal'
import { title } from '@/utilities/filters'
import { goTo } from '@/utilities/helpers/router'
import { formatDate, handleSearch, orderBy } from '@/views/pages/billing/billingViewHelpers'
import Vue from 'vue'
import ClientMixin from '@/mixins/client'
import ExportButton from '@/components/pages/ExportButton.vue'

export default {
  name: 'PackagingPricing',
  mixins: [ClientMixin],
  components: {
    'additional-pricing-create-modal': AdditionalPricingCreateModal,
    'additional-pricing-edit-modal': AdditionalPricingEditModal,
    'export-button': ExportButton,
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
    modal: {
      component: '',
      show: false,
    },
  }),
  computed: {
    box() {
      return this.$store.getters['billing/getSelectedBox']
    },
    exportUrl() {
      return this.billingPriceLists?.exportUrl
    },
    exportName() {
      return this.$options.name
    },
    filters() {
      // TODO: Activate when filtering available via the API
      return null
    },
    headers() {
      return [
        {
          text: title(this.$t('billing.priceListCode')),
          align: 'start',
          sortable: false,
          value: 'priceListCode',
        },
        {
          text: title(this.$t('common.description')),
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('common.price')),
          sortable: false,
          value: 'price',
        },
        {
          text: title(this.$t('billing.originRegion')),
          sortable: true,
          value: 'originRegion',
        },
        {
          text: title(this.$t('common.updated')),
          sortable: false,
          value: 'updatedAt',
        },
      ]
    },
    billingPriceLists() {
      return this.$store.getters['billing/getBillingPriceLists']
    },
    items() {
      return this.billingPriceLists.data.map(bh => {
        const v = { ...bh }
        v.updatedAt = this.formatDate(v.updatedAt)
        return v
      })
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },
    meta() {
      return this.billingPriceLists.meta || { total: this.items.length }
    },
    orderBy() {
      return orderBy(this)
    },
    serverItemsLength() {
      return this.meta.total || 0
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/billing-price-list'
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
    'modal.show': function(s) {
      if (s === false) this.fetch()
    },
  },
  created() {
    this.$store.dispatch('billing/fetchBillingDefaultPriceLists', {
      params: { itemsPerPage: 1000 }, // return an artificially high number to ensure we pick up future codes
    })
    this.fetchBillingPriceLists()
    this.$store.dispatch('billing/fetchRegions')
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options, orderBy, search } = this
        const { page } = options
        const params = {}
        if (page) params.page = page
        if (search) params.query = search
        if (orderBy) params.orderBy = orderBy
        this.$store.dispatch('billing/fetchBillingPriceLists', { params, url: this.url })
      }
    },
    fetchBillingPriceLists() {
      const params = { url: '/huboo-box/' + this.box?.hubooBox + '/billing-price-list' }
      this.$store.dispatch('billing/fetchBillingPriceLists', params)
    },
    formatDate(d) {
      return formatDate(this, d)
    },
    goTo,
    handleFilter(e) {
      // TODO: implement filtering
      console.debug('filtering...', e)
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    handleUpdateOptions(e) {
      Vue.set(this, 'options', e)
    },
    onCreateSelected() {
      this.modal = { component: 'additional-pricing-create-modal', show: true }
    },
    onRowSelected(v) {
      this.selected = v
      this.$store.commit('billing/setSelectedRegion', v?.originRegion)
      this.modal = { component: 'additional-pricing-edit-modal', show: true }
    },
    onSelectedUpdated() {
      this.selected = this.$store.getters['billing/getSelectedBillingPriceList']
      this.$store.commit('billing/setSelectedRegion', this.selected?.originRegion)
    },
    onRemove() {
      this.$store
        .dispatch('billing/removeBillingPriceLists', {
          id: this.selected.id,
        })
        .finally(() => {
          this.fetch()
        })
    },
    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },
  },
}
</script>

<style lang="scss" scoped></style>
